// Most Replayed Moments fetcher page

import { useEffect } from 'react';
import React, {useState} from 'react';


const apiKey = "sk-gBQmjrecIZgXZeip4966F0950aBa4b7687Ea03DdF72171Eb";
const apiUrl = "https://api.aiguoguo199.com/v1/chat/completions";
const standartMessage = "You are master SEO expert witch can make a viral video. Generate title, description and viral hashtags on format Title:'message' , Description:'message' and Hashtags:'message' to easy parsing. for this description of video:" ;
const client_id = "726589466583-f47ece25te8a1t2limibqfi0pf8mhqst.apps.googleusercontent.com"

// async function fetchSeoCompletion(videoInput) { 


//   const response = await fetch(apiUrl,{
//     method: "POST",
//     headers: {
//       "Content-Type" : "application/json",
//       "Authorization": `Bearer ${apiKey}`,
//     },
//     body: JSON.stringify({
//       model: "gpt-4o",
//       messages: [{ role: "user", content: `${standartMessage} ${videoInput}` }],
//     }),
//   });

//   if (!response.ok) {
//     const errorText = await response.text();
//     console.error("Fetch error:", errorText);
//     throw new Error(`HTTP error! Status: ${response.status}`);
//   }
//   const data = await response.json();
//   return data;
// }



export const LoginPage = () => {

    const client_id = "726589466583-f47ece25te8a1t2limibqfi0pf8mhqst.apps.googleusercontent.com"
    const REDIRECT_URI = "https://flamingoshorts.ru/main"
    const SCOPES="https://www.googleapis.com/auth/youtube.readonly"


    const handleAuthClick = () => {
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?` +
          `client_id=${client_id}&` +
          `redirect_uri=${REDIRECT_URI}&` +
          `response_type=token&` +
          `scope=${SCOPES}`;
        window.location.href = authUrl;
      };

      const handleResponse = () => {
        const hash = window.location.hash;
        if (hash) {
          const params = new URLSearchParams(hash.substring(1));
          const accessToken = params.get('access_token');
          if (accessToken) {
            console.log('Access Token:', accessToken);
            // You can now use the access token to make API calls
            fetchYouTubeData(accessToken);
          }
        }
      };

      const fetchYouTubeData = async (accessToken) => {
        const response = await fetch('https://www.googleapis.com/youtube/v3/channels?part=snippet&mine=true', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        console.log('YouTube Data:', data);
      };


    useEffect(() => {
        handleResponse();
    }, []);

      return (

            <div>
                
                    <button onClick={handleAuthClick}>Sign In with Google</button>
                
                    {/* <div>
                        <button onClick={handleSignOutClick}>Sign Out</button>
                        <button onClick={fetchVideos}>Fetch My Videos</button>
                    </div> */}
                

            </div>

      )

            
}
