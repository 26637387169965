// Most Replayed Moments fetcher page

import React, {useState} from 'react';
import NavBar from '../uicomponents/NavBar/navbar';
import './seo.css'

const apiKey = "sk-gBQmjrecIZgXZeip4966F0950aBa4b7687Ea03DdF72171Eb";
const apiUrl = "https://api.aiguoguo199.com/v1/chat/completions";
const standartMessage = "You are master SEO expert witch can make a viral video. Generate title, description and viral hashtags on format Title:'message' , Description:'message' and Hashtags:'message' to easy parsing. for this description of video:" ;


async function fetchSeoCompletion(videoInput) { 
  // console.log("API Key:", apiKey);
  // console.log("API URL:", apiUrl);
  // console.log("Standard Message:", standartMessage);
  // console.log("Video Input:", videoInput);

  const response = await fetch(apiUrl,{
    method: "POST",
    headers: {
      "Content-Type" : "application/json",
      "Authorization": `Bearer ${apiKey}`,
    },
    body: JSON.stringify({
      model: "gpt-4o",
      messages: [{ role: "user", content: `${standartMessage} ${videoInput}` }],
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error("Fetch error:", errorText);
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data = await response.json();
  const processedData = await processResponse(data.choices[0].message.content);
  return processedData;
}

const processResponse = async (rawText) => {
  const cleanedText = rawText.replace(/['"]/g, '').trim();

  // Match Title, Description, and Hashtags
  const titleMatch = cleanedText.match(/Title:\s*(.+)/i); // Case insensitive
  const descriptionMatch = cleanedText.match(/Description:\s*(.+)/i); // Case insensitive
  const hashtagsMatch = cleanedText.match(/Hashtags:\s*(.+)/i); // Case insensitive

  return {
    title: titleMatch && titleMatch[1] ? titleMatch[1].trim() : 'Title not found',
    description: descriptionMatch && descriptionMatch[1] ? descriptionMatch[1].trim() : 'Description not found',
    hashtags: hashtagsMatch && hashtagsMatch[1] ? hashtagsMatch[1].trim() : 'Hashtags not found'
  };


}

export const SeoPage = () => {

  const [videoInput, setVideoInput] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false)

  const handleInputChange = (event) => {
    setVideoInput(event.target.value);
  };

  const handleGenerateSEO = async () => {
    setLoading(true)
    console.log(videoInput)
    try {
      const fetchedResponse = await fetchSeoCompletion(videoInput);
      setResponse(fetchedResponse); 
      console.log(response);
      setLoading(false)
      
    } catch (error) {
      console.error("Error fetching SEO completion:", error);
      
    } 
    

  };

      return (
        <>
          <NavBar />

          <div className='main'>
            <div className='about-video'>
                <h2>🎬Tell me about your video🎬</h2>
                <input className='input-short'
                type='text'
                placeholder='My video about MMA fighters🥊'
                value={videoInput}
                onChange={handleInputChange}
                />
            </div>

            {loading ? (
              <div className='button-video'>
              <button className='generate-button'
              > 
                  Loading...
              </button>
            </div>
            ):(
              <div className='button-video'>
              <button className='generate-button'
              onClick={handleGenerateSEO}
              > 
                  🤖Generate SEO for my video🤖
              </button>
            </div>
            )}
            

             {response && (
              <>
             <div className='gen-title'>
                <h2>📢Generated Title📢</h2>
                <p>{response.title}</p>
             </div>

             <div className='gen-description'>
              <h2>🗒️Generated Description🗒️</h2>
              <p>{response.description}</p>
             </div>

             <div className='gen-hashtags'> 
               <h2>📌Generated Hastags</h2>
               <p>{response.hashtags}</p>
             </div>
             </>
             )}
          </div>
    
        </>
      )
}
