// Most Replayed Moments fetcher page

import React, {useState} from 'react';
import NavBar from '../uicomponents/NavBar/navbar';
import './mrm.css'


export const MrmPage = () => {
    const [youtubeUrl, setYoutubeUrl] = useState('');

    const handleInputChange = (event) => {
        setYoutubeUrl(event.target.value);
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('YouTube URL:', youtubeUrl);
      };

      return (
        <>
          <NavBar />
      
          <div className="home-page-container">
            <div className="home-page-header">
              <h1>Extract Most Replayed Moments from YouTube</h1>
            </div>
      
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>
                  Enter YouTube video URL:
                  <input
                    type="text"
                    value={youtubeUrl}
                    onChange={handleInputChange}
                    placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                  />
                </label>
              </div>
              <button className='button'>Extract Most Replayed Moments</button>
            </form>
          </div>
        </>
      )
}
