import React, { useState } from 'react';
import NavBar from '../uicomponents/NavBar/navbar';
import './about.css';

export const AboutPage = () => {
  return (
    <>
      <NavBar />
      
      <div className="home-page-container">
        <div className="home-page-header">
          <h1>Почему вам стоит инвестировать в наш проект?</h1>
        </div>
        
        <div className="reasons-to-invest">
          <h2>Будущее контента потребления</h2>
          <p>Когда-то все говорили, что ТВ приведет к деградации общества, Газеты считались чтивом для глупых, и где эти люди сейчас?</p>
          <p>Короткие видео - информационное будущее. Каждый потребляет хотя бы малую долю этого контента, а на рекламе зарабатывают миллионы.</p>
          
          <h2>Смысл проекта</h2>
          <p>Почему люди малоактивно переходят с YouTube на отечественные платформы - контента мало. Давайте дадим хороший инструмент для генерации контента.</p>
          
          <h2>Что уже есть</h2>
          <ul>
            <li><span style={{ color: 'green' }}>&#10004;</span> Взяли исходный код нейросетевой модели и подогнали его под свои нужды</li>
            <li><span style={{ color: 'green' }}>&#10004;</span> Сделали API для бэкенда и нейросети, чтобы была качественная передача данных</li>
            <li><span style={{ color: 'green' }}>&#10004;</span> Подключили простой фронтенд на React - минимум отвлекающих блоков - максимум функционала</li>
          </ul>
          
          <h2>На что способен наш сервис</h2>
          <ul>
            <li className="highlight">Может выделить самые интересные моменты из любого вашего видео</li>
          </ul>
          
          <h2>Что предстоит сделать</h2>
          <ul>
            <li><span style={{ color: 'blue' }}>&#10003;</span> Форматировать видео под формат 9:16 для мобильных телефонов</li>
            <li><span style={{ color: 'blue' }}>&#10003;</span> Иметь личный кабинет - возможность просматривать историю видео и тд</li>
            <li><span style={{ color: 'blue' }}>&#10003;</span> Возможность обрезать по времени готовый короткий ролик</li>
            <li><span style={{ color: 'blue' }}>&#10003;</span> Генерация субтитров</li>
          </ul>
          <p className="highlight">Все это мы хотим доделать за следующие 5 недель</p>

          <div className="contact-block">
          <h2>Связаться с нами</h2>
          <p>Если у вас есть вопросы или предложения, пожалуйста, свяжитесь с нами:</p>
          <ul>
            <li><a href="mailto:betayev.ilya2004@gmail.com">betayev.ilya2004@gmail.com</a></li>
            <li><a href="https://t.me/notbot1512" target="_blank">@notbot1512</a></li>
          </ul>
        </div>
        
        </div>
      </div>
    </>
  );
};