import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { HomePage } from './pages/home';
import { MrmPage } from './pages/mrm';
import { AboutPage } from './pages/about';
import { SeoPage } from './pages/seo';
import { LoginPage } from './pages/login/login';
import { MainPage } from './pages/main/main';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/most-replayed-moments" element={<MrmPage/>} />
        <Route path="/SEOgenerator" element={<SeoPage/>}/>
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/login" element={<LoginPage/>} />
        <Route path="/main" element={<MainPage/>}ß />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
