import { useEffect } from 'react';
import React, {useState} from 'react';
import NavBar from '../uicomponents/NavBar/navbar';
import './home.css'

import { v4 as uuidv4 } from 'uuid';

const HomePage = () => {
    const [selectedFile, setSelectedFile] = useState(null)
    const [uploading, setUploading] = useState(false);
    const [isValidFile, setIsValidFile] = useState(false);
    const [videoPreview, setVideoPreview] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [longvideoUid, setLongVideoUid] = useState('');
    const [videoStatus, setVideoStatus] = useState('');
    const [shortsVideos, setShortsVideos] = useState([]); // new state to store shorts videos


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === 'video/mp4') {
                setSelectedFile(file);
                console.log('File fits')
                setIsValidFile(true);
                setVideoPreview(URL.createObjectURL(file))
            } else {
                alert('Выберайте видео')
                setSelectedFile(null);
                setIsValidFile(false);
                setVideoPreview(null);
            }
        }
        
    }

    
    const handleUpload = () => {
        setUploading(true);
        const formData = new FormData();
        formData.append('video_file', selectedFile);
        const videoId = uuidv4();
        formData.append('frontend_uid', videoId);
        formData.append('status', 'Uploaded')
        console.log(videoId);
        

        formData.forEach((value, key) => {
            console.log(`${key}: ${value}`);
          });

        fetch('https://flamingoshorts.ru/api/upload', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data.frontend_uid)
                setIsUploaded(true);
                // setUploading(false);
                const intervalId = checkVideoStatusInterval(data.frontend_uid); // Store the interval ID
            
            })
            .catch((error) => console.error(error))
            .finally(() => setUploading(false))
            console.log('Файл загружен')
    }

    const checkVideoStatus = (videoId) => {
        fetch(`https://flamingoshorts.ru/api/check_video_status/?video_id=${videoId}`)
            .then(response => response.json())
            .then((data) => {
                const status = data.status;
                setVideoStatus(status);
                if (status === 'splitting') {
                    // Show loading div
                    console.log('Video is being split...');
                } else if (status === 'splitted') {
                    // Print message that file was splitted and now it's processing
                    console.log('File was splitted and is now processing...');
                } else if (status === 'Processed') {
                    // Fetch shorts videos
                    fetch(`https://flamingoshorts.ru/api/getshorts/?frontend_uid=${videoId}`)
                        .then(response => response.json())
                        .then((data) => {
                            setShortsVideos(data);
                        })
                        .catch((error) => console.error(error));
                }
            })
            .catch((error) => console.error(error))
    }

    const checkVideoStatusInterval = (videoId) => {
        const intervalId = setInterval(() => {
            checkVideoStatus(videoId);
        }, 1000);
        return intervalId;
    }

    useEffect(() => {
        let intervalId; // Declare the interval ID variable
        return () => {
            clearInterval(intervalId); // Clear the interval using the stored ID
        }
    }, [])

    const handleCancel = () => {
        setVideoPreview(null);
        setSelectedFile(null);
        setIsValidFile(false);
    }

    const handleProcess = () => {
        console.log('Processing')
    }
    return (
    <>
    <NavBar />

    <div className="home-page-container">
        <div className="home-page-header">
            <h1>Загрузите ваше видео</h1>
            <p>Не больше 30 минут, максимальное качество 720p</p>
        </div>
        <div className="input-field">
            <input type="file" onChange={handleFileChange} />
        </div>
        {isValidFile && (
            <div>
            <video width="400" height="300" controls className='video_preview'>
                <source src={videoPreview} type="video/mp4" />
            </video>
            <div className="button-container"> 
            {uploading ? (
                <p></p>
            ) : isUploaded ? (
                <p></p>
            ) : (
                <button className="button upload-button" onClick={handleUpload}>
                Обработать файл
                </button>
            )}
                <button className="button cancel-button" onClick={handleCancel}>
                    Отмена
                </button>
            </div> 
            </div>
        )}
        {uploading && <p className="uploading">Ожидайте, если видео длинное - загрузка может идти долго</p>}

        {isUploaded && (
            <div className="video-status">
            <p>Статус: {videoStatus}</p>
            {videoStatus === 'Uploaded' && (
              <p className="success">Файл загружен успешно - подождите несколько минут, пока наша служба не разобьет это видео. Это обычно занимает 1-5 минут.</p>
            )}
            {videoStatus === 'Splitting' && (
              <p className="info">Видео было все еще разделяется...</p>
            )}
            {videoStatus === 'Splitted' && (
              <p className="success">Сейчас наша нейросеть обрабатывает ваше видео, наберитесь терпения</p>
            )}
            {videoStatus === 'Processing' && (
              <p className="info">Нейросеть внимательно изучает ваше видео и находит самые интересные моменты. Кстати, видео очень интересное</p>
            )}

            {videoStatus === 'Processed' && (
                            <div>
                                <p className="success">Видео было успешно обработано</p>
                                <ul className="video-list">
                                    {shortsVideos.map((video, index) => (
                                        <li key={index}>
                                        <video width="400" height="300" controls>
                                            <source src={`https://flamingoshorts.ru/media/${video.shorts_path.replace('/var/www/flamingoshorts.ru/media/', '')}`} type="video/mp4" />
                                        </video>
                                        <p>{video.title}</p>
                                        </li>
                                    ))}
                                    </ul>
                            </div>
                        )}
            </div>
        )}

    </div>

    </>
    )
}

export { HomePage };